import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"; 
import DownloadOutlined from "@material-ui/icons/GetAppOutlined"; 
import { pdfIcon, closeIcon, downloadIcon } from "../../blocks/educational-user-profile/src/assets";

interface DocumentViewerProps {
  fileUrl: string;
  documentName: string;
  onClose: () => void;
}

export default class DocumentViewerPopup extends React.Component<DocumentViewerProps> {
  render() {
    const { fileUrl, documentName, onClose } = this.props;

    return (
      <Box
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          zIndex: 999, 
        }}
  >
    <Box
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "33%",
          height: "100%",
          backgroundColor: "#fff",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            padding: "16px",
            borderBottom: "1px solid #E2E8F0",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src= {pdfIcon}
              alt="PDF Icon"
              style={{ width: "20px", height: "20px" }}
            />
            <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
              {documentName}
            </Typography>
          </Box>
          <Box>
          <button
               onClick={async () => {
                try {
                  const response = await fetch(fileUrl); 
                  const blob = await response.blob(); 
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob); 
                  link.download = documentName || "document"; 
                  link.click(); 
                  URL.revokeObjectURL(link.href); 
                } catch (error) {
                  console.error("Failed to download file:", error);
                }
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "6px",
                marginRight: "8px",
              }}
            >
              <img
                src={downloadIcon}
                alt="Download Icon"
                style={{ width: "20px", height: "20px" }}
              />
            </button>
            <button
              onClick={onClose}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "6px",
              }}
            >
              <img
                src={closeIcon}
                alt="Close Icon"
                style={{ width: "20px", height: "20px" }}
              />
            </button>
          </Box>
        </Box>

        {/* Embedded PDF Viewer */}
        <Box
          style={{
            flex: 1,
            padding: "16px", 
            overflowY: "auto",
            backgroundColor: "#F1F5F9"
          }}
        >
          <iframe
            src={`${fileUrl}#toolbar=0`}
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid #E2E8F0",
              borderRadius: "8px", 
            }}
            title="Document Viewer"
            className="iframe-scroll-container"
          />
        </Box>
      </Box>

  </Box>
      
    );
  }
}

export const userProfileImg = require("../assets/user-profile.png");
export const ProfileImg = require("../assets/image1.png");
export const card = require("../assets/card.png");
export const vat = require("../assets/vat.png");
export const home = require("../assets/home.png");
export const email = require("../assets/email.png");
export const auth = require("../assets/auth.png");
export const taxino = require("../assets/taxino.png");
export const authId = require("../assets/authId.png");
export const iban = require("../assets/Iban.png");
export const branch = require("../assets/branch.png");
export const categories = require("../assets/categories.png");
export const location = require("../assets/location.png");
export const ophours = require("../assets/ophours.png");
export const attatchfile = require("../assets/attatchfile.png");
export const time = require("../assets/time.png");
export const pdfIcon = require("../assets/pdfIcon1.png");
export const topBackImg = require("../assets/TopBackImg.png");
export const branchCode = require("../assets/code.png");
export const branchContactNumber = require("../assets/number.png");
export const pencil = require("../assets/pencil.png");
export const downloadIcon = require("../assets/download.png");
export const closeIcon = require("../assets/close_24px.png");
export const datepicker=require("../assets/datepicker.svg");
export const handburger=require("../assets/handburger.svg");
export const closebtn=require("../assets/close.svg");
export const clockIcon=require("../assets/clock.svg");
export const cardDatepick=require("../assets/cardDatepick.svg");
export const attendeesIcon=require("../assets/attendes.svg");
export const attendeesIcon2=require("../assets/attendes2.svg");
export const modalSuccessIcon=require("../assets/modalsucces.svg");
export const datePickerIcon=require("../assets/datePicker.svg");
export const clockIcon2=require("../assets/clockIcon2.svg");
export const dropdownPersonIcon=require("../assets/dropdownPersonIcon.svg");
export const dropdownArrow=require("../assets/dropdownArrow.svg");
export const modalErrorIcon=require("../assets/modalError.svg");






